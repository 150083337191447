import React, { Component } from 'react';
import styled from 'styled-components';
import StepZilla from 'react-stepzilla';
import { Step1, Step2, Step3 } from '../components/Wizzard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateActiveStep,
  updateResourceStep1,
  updateFillAttempt_Step_1,
  updateCodEmpresa,
  updateCodProcesso,
  updateFillAttempt_Step_2,
  updateFillAttempt_Step_3,
  updateRes2,
  updateEnderecoDivulga,
  UpdateValorAnalise,
} from '../redux/actions';

import { JoinUs, JoinInformation } from '../components';
//import CompanyForm from '../containers';
import theme from '../styles/theme';

import '../assets/css/wizard.css';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h4`
  font-size: ${theme.fontSizeLg * 0.9}px;
  font-weight: ${theme.bodyWeight};
  margin: 40px 0 15px 0;
`;

const Line = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${theme.colors.primary};
  margin: 0 0 40px 0;
`;

const GrayLine = styled.div`
  background-color: ${theme.colors.grey2};
  height: 1px;
  width: 85%;
  margin: 60px 0 0px 0;
`;

const JoinWrapper = styled.div`
  width: 85%;
  min-height: 350px;
`;

const Wizzard = styled.div`
  width: 90%;
`;

class JoinUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
    };

    this.sampleStore = {
      email: '',
      gender: '',
      savedToCloud: false,
    };
  }

  getStore() {
    return this.sampleStore;
  }

  updateStore(update) {
    this.sampleStore = {
      ...this.sampleStore,
      ...update,
    };
  }

  render() {
    const { activeStep, updateActiveStep } = this.props;
    console.log('active step', activeStep);
    const steps = [
      { name: 'Início', component: <Step1 {...this.props} /> },
      { name: 'Dados Complementares', component: <Step2 {...this.props} /> },
      { name: 'Pagamento', component: <Step3 {...this.props} /> },
    ];

    return (
      <Wrapper>
        <Title>SEJA CERTIFICADO</Title>
        <Line />
        {/* <StyledTabs
          content={tabsContent}
          onClick={() => console.log('teste')}
          components={components}
          lineWidth={'90%'}
        /> */}
        <Wizzard className="step-progress">
          <StepZilla
            steps={steps}
            preventEnterSubmission={true}
            nextTextOnFinalActionStep={'Próximo >'}
            startAtStep={activeStep}
            onStepChange={step => updateActiveStep(step)}
            stepsNavigation={false}
            prevBtnOnLastStep={false}
            nextButtonText={'Próximo >'}
            backButtonText={'< Anterior'}
          />
        </Wizzard>
        <GrayLine />
        <JoinInformation />
        <GrayLine />
        <JoinWrapper>
          <JoinUs />
        </JoinWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = store => ({
  codEmpresa: store.wizardState.codEmpresa,
  codProcesso: store.wizardState.codProcesso,
  activeStep: store.wizardState.activeStep,
  step1_newValue: store.wizardState.step1_newValue,
  step2_newValue: store.wizardState.step2_newValue,
  enderecoDivulga: store.wizardState.enderecoDivulga,
  alreadySavedFillAttemptStep1: store.wizardState.alreadySavedFillAttemptStep1,
  alreadySavedFillAttemptStep2: store.wizardState.alreadySavedFillAttemptStep2,
  alreadySavedFillAttemptStep3: store.wizardState.alreadySavedFillAttemptStep3,
  valorAnalise: store.wizardState.ValorAnalise,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateActiveStep,
      updateResourceStep1,
      updateFillAttempt_Step_1,
      updateCodEmpresa,
      updateCodProcesso,
      updateFillAttempt_Step_2,
      updateFillAttempt_Step_3,
      updateRes2,
      updateEnderecoDivulga,
      UpdateValorAnalise,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinUsPage);
