import React, { Component } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import InputMask from 'react-input-mask';
import Modal from '../../components/Modal/Modal';
import jsonp from 'jsonp';
import Select from 'react-select';
import get from 'get-value';
import ClipLoader from 'react-spinners/ClipLoader';

const Label = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
`;

const Smallbtn = styled.small`
  cursor: pointer;
`;

class Step1 extends Component {
  constructor(props) {
    super(props);

    const step1_newValue = this.props.step1_newValue;

    this.state = {
      codEmpresa: this.props.codEmpresa,
      data_situ: '',
      abertura: '',
      resource: {
        cnpj: step1_newValue.cnpj,
        nomeEmpresa: step1_newValue.nomeEmpresa,
        email: step1_newValue.email,
        telefone: step1_newValue.telefone,
        cidade: step1_newValue.cidade,
        uf: step1_newValue.uf,
        logradouro: step1_newValue.logradouro,
        numero: step1_newValue.numero,
        cep: step1_newValue.cep,
        complemento: step1_newValue.complemento,
        segmento: step1_newValue.segmento,
        segmento_outros: step1_newValue.segmento_outros,
        processo: step1_newValue.processo,
        promo_code: this.props.step1_newValue.promo_code,
      },
      waitCnpj: false,
      showModal: false,
      modalMessage: '',
      modalTitle: '',
      redirectToHome: false,
      redirectToStep: false,
      stepToRedirect: null,
      lockInput: false,
      changeCnpj: false,
      selectedSegment: step1_newValue.segmento,
      segmentSelectOptions: [],
      selectedProcess: step1_newValue.processo,
      processSelectOptions: [],
      loading: false,
      telefoneReceita: false,
      stateValorAnalise: null,
    };

    //this.validationCheck = this.validationCheck.bind(this);
    this.isValidated = this.isValidated.bind(this);

    this.handleChange = (prop, value) => {
      this.setState(({ resource }) => ({
        resource: {
          ...resource,
          [prop]: value,
        },
      }));
    };

    /**
     * Returns the select option (used in react-select) component,
     * based on the resource retrieved from database.
     * @param segment
     * @return {{value: *, label: string, data: *}}
     */
    this.buildSegmentOptionFromTheResource = segment => ({
      value: segment.codSegmento,
      label: segment.segmento,
      data: {
        value: segment.codSegmento,
        label: segment.segmento,
      },
    });

    /**
     * Loads from API all available segments, to build up the select options in the form.
     */
    this.loadAvailableSegments = () => {
      // eslint-disable-next-line no-undef
      Axios.get(`${process.env.GATSBY_API_URL_PROD}Segmento/JSON`).then(
        response => {
          const segmentOptions = [];
          response.data.forEach(item => {
            const segmentOption = this.buildSegmentOptionFromTheResource(item);
            segmentOptions.push(segmentOption);
          });
          this.setState({
            segmentSelectOptions: segmentOptions,
          });
        }
      );
    };

    /**
     * Callback function to when user selects some value on Segmento
     * form field. Saves status to this component state.
     * @param selectedSegment
     */
    this.handleChangeOnSegment = selectedSegment => {
      this.setState(prevState => ({
        selectedSegment,
        resource: {
          ...prevState.resource,
          segmento: get(selectedSegment, 'data', null),
        },
      }));
    };

    /**
     * Returns the select option (used in react-select) component,
     * based on the resource retrieved from database.
     * @param process
     * @return {{value: *, label: string, data: *}}
     */
    this.buildProcessOptionFromTheResource = process => ({
      value: process.codTipoProcesso,
      label: process.tipoProcesso,
      data: {
        value: process.codTipoProcesso,
        label: process.tipoProcesso,
      },
    });

    /**
     * Loads from API all available process, to build up the select options in the form.
     */
    this.loadAvailableProcess = () => {
      // eslint-disable-next-line no-undef
      Axios.get(`${process.env.GATSBY_API_URL_PROD}TipoProcesso/JSON`).then(
        response => {
          const processOptions = [];
          response.data.forEach(item => {
            const processOption = this.buildProcessOptionFromTheResource(item);
            processOptions.push(processOption);
          });
          this.setState({
            processSelectOptions: processOptions,
          });
        }
      );
    };

    /**
     * Callback function to when user selects some value on Process
     * form field. Saves status to this component state.
     * @param selectedProcess
     */
    this.handleChangeOnProcess = selectedProcess => {
      this.setState(prevState => ({
        selectedProcess,
        resource: {
          ...prevState.resource,
          processo: get(selectedProcess, 'data', null),
        },
      }));
    };

    this.clearFirstStep = () => {
      const { resource } = this.state;

      this.setState({
        resource: {
          ...resource,
          cnpj: '',
          nomeEmpresa: '',
          email: '',
          telefone: '',
          segmento: {
            label: '',
            value: '',
          },
          segmento_outros: '',
          promo_code: '',
        },
        lockInput: false,
        selectedSegment: {
          label: '',
          value: '',
        },
      });
    };

    this.fillFirsStep = data => {
      const { resource } = this.state;
      this.setState(
        {
          telefoneReceita: data.telefone ? true : false,
          lockInput: true,
          changeCnpj: true,
          waitCnpj: false,
          data_situ: data.data_situacao,
          abertura: data.abertura,
          resource: {
            ...resource,
            nomeEmpresa: data.nome,
            telefone: data.telefone,
            cidade: data.municipio,
            uf: data.uf,
            logradouro: data.logradouro,
            numero: data.numero,
            cep: data.cep,
            complemento: data.complemento,
          },
        },
        () => {
          this.validationCheck();
        }
      );
    };

    this.checkIfHasLessThan2Years = data => {
      /**
       * Verifica se a empresa tem menos de 2 anos. Se tiver, não prossegue e encaminha para erro
       * Caso a empresa seja Buffet, ela precisa ter pelo menos 5 anos.
       */

      const sit = data.abertura.split('/');
      const hoje = new Date();
      const dataAbertura = new Date(sit[2], sit[1] - 1, sit[0]);

      const diffDias = (hoje - dataAbertura) / 1000 / 60 / 60 / 24;

      if (diffDias < 730) {
        this.clearFirstStep();
        this.setState({
          modalTitle: 'ATENÇÃO',
          modalMessage:
            'Seu CNPJ precisa ter pelo menos 2 anos de atividade para que sua empresa possa continuar no processo.',
          showModal: true,
          waitCnpj: false,
        });

        return true;
      }

      return false;
    };

    this.checkIfBuffetHasLessThan5Years = data => {
      /**
       * Caso a empresa seja Buffet, ela precisa ter pelo menos 5 anos.
       */

      const { resource } = this.state;

      const sit = data.split('/');
      const hoje = new Date();
      const dataAbertura = new Date(sit[2], sit[1] - 1, sit[0]);

      const diffDias = (hoje - dataAbertura) / 1000 / 60 / 60 / 24;

      if (resource.segmento.value === '6') {
        console.log(diffDias);
        if (diffDias < 1825) {
          this.clearFirstStep();
          this.setState({
            modalTitle: 'ATENÇÃO',
            modalMessage:
              'Como seu segmento é Buffet, seu CNPJ precisa ter pelo menos 5 anos de atividade para que sua empresa possa continuar no processo.',
            showModal: true,
            waitCnpj: false,
          });

          return true;
        }
      }

      return false;
    };

    this.checkIfMeiBuffetSalao = data => {
      /* Verifica se a empresa é MEI caso buffet ou espaço, se for não prossegue para a proxima tela
       */
      const nat = data.natureza_juridica.split(' - ');

      if (nat[0] === '213-5') {
        this.clearFirstStep();
        this.setState({
          modalTitle: 'ATENÇÃO',
          modalMessage:
            'Neste momento, o certificado TRUST não pode ser emitido para MEI nos segmentos de Buffet e Espaço. Direcionando para o site principal...',
          showModal: true,
          waitCnpj: false,
        });
        return true;
      }

      return false;
    };

    this.checkIfBaixado = data => {
      /**
       * Verifica se a empresa está baixada, se estiver não prossegur para a próxima tela
       */
      if (data.situacao === 'BAIXADA') {
        this.clearFirstStep();
        this.setState({
          modalTitle: 'ATENÇÃO',
          modalMessage:
            'O CNPJ informado consta como baixado na Receita Federal, e não é possível continuar com o processo. Direcionando para o site principal...',
          showModal: true,
          waitCnpj: false,
          redirectToHome: true,
        });
        return true;
      }
    };

    this.checkIfCompanyIsRegistered = async () => {
      const { resource } = this.state;

      let formData = new FormData();
      formData.append('cnpj', resource.cnpj);
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      };

      const response = await Axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.GATSBY_API_URL}Empresa/dadosCNPJJSON/`,
        formData,
        config
      );

      if (response.data.codEmpresa !== null) {
        this.setState({
          codEmpresa: response.data.codEmpresa,
        });

        return response.data.codEmpresa;
      }

      return false;
    };

    this.registerCompany = async () => {
      const { resource } = this.state;
      let formData = new FormData();
      formData.append('cnpj', resource.cnpj);
      formData.append('empresa', resource.nomeEmpresa);
      formData.append('email', resource.email);
      formData.append('telefone', resource.telefone);
      formData.append('codSegmento', resource.segmento.value);
      formData.append('outroSegmento', resource.segmento_outros);
      formData.append('codTipoProcesso', resource.processo);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Accept: '*/*',
        },
      };

      const response = await Axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.GATSBY_API_URL}Empresa/gravarBasico/`,
        formData,
        config
      );

      if (response !== null) {
        return response.data;
      }

      return null;
    };

    this.registerProcess = async (codEmpresa, valAnalise) => {
      const { resource } = this.state;
      let formData = new FormData();
      formData.append('codEmpresa', codEmpresa);
      formData.append('codTipoProcesso', resource.processo.value);
      formData.append('codigoPromocional', resource.promo_code);
      formData.append('valorAnalise', valAnalise);
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Accept: '*/*',
        },
      };

      const response = await Axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.GATSBY_API_URL}Processo/Gravar/`,
        formData,
        config
      );

      if (response !== null) {
        return response.data;
      }

      return null;
    };

    this.recordFillAttempt = async (codEmpresa, codStatusPreenchimento) => {
      let formData = new FormData();
      formData.append('codEmpresa', codEmpresa);
      formData.append('codStatusPreenchimento', codStatusPreenchimento);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Accept: '*/*',
        },
      };
      await Axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.GATSBY_API_URL}Processo/gravarTentativaPreenchimento`,
        formData,
        config
      );
    };

    this.checkIfHasOpenProcess = async codEmpresa => {
      let formData = new FormData();
      formData.append('codEmpresa', codEmpresa);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Accept: '*/*',
        },
      };

      const companyHasProcess = await Axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.GATSBY_API_URL}Processo/listarAbertosEmpresa`,
        formData,
        config
      );

      if (companyHasProcess) return companyHasProcess.data[0];

      return false;
    };

    // this.updateValorAnalise = async (valorAnalise) => {
    //   let formData = new FormData();
    //   formData.append('valorAnalise', valorAnalise);
    //   formData.append('codProcesso', this.props.codProcesso);

    //   const config = {
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    //       'Accept':'*/*'
    //     }
    //   }
    //   // eslint-disable-next-line no-undef
    //   const updatedValorAnalise = await Axios.post(`${process.env.GATSBY_API_URL}Processo/atualizarValorAnalise`, formData, config);

    //  if(updatedValorAnalise.data) return updatedValorAnalise.data

    //  return false;
    // };

    this.unmaskCnpj = cnpj => {
      const unmaskedCnpj = cnpj.replace(/[^\w\s]/gi, '');

      return unmaskedCnpj;
    };

    this.listLastCompanyProcess = async cnpj => {
      let formData = new FormData();
      formData.append('cnpj', cnpj);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Accept: '*/*',
        },
      };

      const lastProcess = await Axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.GATSBY_API_URL}Processo/listarUltimoProcessoEmpresa`,
        formData,
        config
      );

      if (lastProcess.data) return lastProcess.data[0];

      return false;
    };

    this.cnpjReceita = async () => {
      // eslint-disable-next-line no-undef
      const url = process.env.GATSBY_RECEITA_CNPJ_URL;
      const { resource } = this.state;

      /**
       * Verifica se o ultimo processo do fornecedor foi aprovado ou nao
       *
       */
      // Busca ultimo processo
      const lastcompanyProcess = await this.listLastCompanyProcess(
        resource.cnpj
      );

      if (lastcompanyProcess !== null && lastcompanyProcess !== undefined) {
        //Processo finalizado
        if (lastcompanyProcess.codStatusProcesso === '6') {
          //checa se foi aprovado

          //aprovado
          if (lastcompanyProcess.codStatusCertificacao === '2') {
            //se sim, checa se certificado é valido

            //valido
            if (lastcompanyProcess.certificadoValido === '1') {
              this.setState({
                modalTitle: 'ATENÇÃO',
                modalMessage:
                  'Já consta em nosso cadastro um certificado válido para este CNPJ!',
                showModal: true,
                waitCnpj: false,
              });
              return;
            }

            //invalido
            if (lastcompanyProcess.certificadoValido == '0') {
              this.setState({
                modalTitle: 'ATENÇÃO',
                modalMessage:
                  'Já consta em nosso cadastro um certificado para este CNPJ mas está vencido! Para renovar entre em contato conosco em certificado@certificadotrust.com.',
                showModal: true,
                waitCnpj: false,
              });
              return;
            }
          }

          //reprovado
          if (lastcompanyProcess.codStatusCertificacao === '3') {
            if (Number(lastcompanyProcess.diasProcesso) < 30) {
              this.setState({
                modalTitle: 'ATENÇÃO',
                modalMessage:
                  'Já consta em nosso cadastro um processo concluido neste CNPJ à menos de 30 dias. É necessário aguardar este período para nova tentativa.',
                showModal: true,
                waitCnpj: false,
              });
              return;
            }
          }
        }

        //Processo cancelado
        if (
          lastcompanyProcess.codStatusProcesso === '7' ||
          lastcompanyProcess.codStatusProcesso === '8'
        ) {
          if (Number(lastcompanyProcess.diasProcesso) < 30) {
            this.setState({
              modalTitle: 'ATENÇÃO',
              modalMessage:
                'Consta em nosso cadastro um processo cancelado com este CNPJ à menos de 30 dias. É necessário aguardar este período para nova tentativa.',
              showModal: true,
              waitCnpj: false,
            });
            return;
          }
        }
      }

      if (resource.cnpj === '') return;

      this.setState({
        waitCnpj: true,
        resource: {
          ...resource,
          nomeEmpresa: '',
          telefone: '',
        },
      });
      const unmaskedCnpj = await this.unmaskCnpj(resource.cnpj);

      jsonp(`${url}${unmaskedCnpj}`, null, async (err, data) => {
        if (err) {
          this.clearFirstStep();
          this.setState({
            modalTitle: 'Erro ao buscar CNPJ',
            modalMessage: 'CNPJ não encontrado',
            showModal: true,
            waitCnpj: false,
          });
        } else {
          if (data.status === 'ERROR') {
            this.setState({
              modalTitle: 'Erro ao buscar CNPJ',
              showModal: true,
              modalMessage: data.message,
              waitCnpj: false,
              resource: {
                ...resource,
                cnpj: '',
                nomeEmpresa: '',
                telefone: '',
              },
            });
            return;
          }

          const hasLessThan2Years = await this.checkIfHasLessThan2Years(data);
          // const isMeiBuffetSalao = await this.checkIfMeiBuffetSalao(data);
          const isBaixado = await this.checkIfBaixado(data);

          if (!hasLessThan2Years && !isBaixado) this.fillFirsStep(data);
        }
      });
    };

    this.validationCheck = () => {
      const { resource } = this.state;
      // save on redux store
      this.props.updateResourceStep1(resource);
      const userInput = this._grabUserInput(); // grab user entered vals
      const validateNewInput = this._validateData(userInput); // run the new input against the validator

      this.setState(
        Object.assign(
          userInput,
          validateNewInput,
          this._validationErrors(validateNewInput)
        )
      );
    };

    this.ChangeCnpjInput = () => {
      this.setState({
        changeCnpj: false,
        lockInput: false,
      });
    };

    this.beforeMaskedValueChange = newState => {
      let { value } = newState;
      var selection = newState.selection;

      if (selection && selection.end === 14) {
        value = value.replace('-', '');
        value = value.slice(0, 9) + '-' + value.slice(9, 13);
      }
      return {
        value,
        selection,
      };
    };

    /**
     * Loads from API all available values
     */
    this.loadValorAnalise = () => {
      Axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.GATSBY_API_URL_PROD}Config/retornarJSON/valorAnalise/`
      ).then(response => {
        this.setState({
          stateValorAnalise: response.data[0].valConfig,
        });
      });
    };

    this.checkIfHasClosedProcess = async cnpj => {
      let formData = new FormData();
      formData.append('cnpj', cnpj);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Accept: '*/*',
        },
      };

      const lastClosedProcess = await Axios.post(
        `${
          // eslint-disable-next-line no-undef
          process.env.GATSBY_API_URL
        }Processo/listarUltimoProcessoFechadoEmpresa`,
        formData,
        config
      );

      console.log(lastClosedProcess);

      if (lastClosedProcess.data) return lastClosedProcess.data[0];

      return false;
    };
  }

  componentDidMount() {
    this.loadAvailableSegments();
    this.loadAvailableProcess();
    this.loadValorAnalise();
  }

  componentWillUnmount() {}

  async isValidated() {
    const { resource, abertura, stateValorAnalise } = this.state;
    this.setState({ loading: true });
    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator

    // save on redux store
    this.props.updateResourceStep1(resource);

    // if full validation passes
    if (
      Object.keys(validateNewInput).every(k => {
        return validateNewInput[k] === true;
      })
    ) {
      // Check if its Buffet with less tgan 5 years
      const isBuffetWithLessThan5 = await this.checkIfBuffetHasLessThan5Years(
        abertura
      );

      if (isBuffetWithLessThan5) {
        this.setState({
          modalTitle: 'ATENÇÃO',
          modalMessage:
            'Sua empresa é do segmento Buffet. Para continuar, sua empresa precisa ter mais de 5 anos.',
          showModal: true,
          redirectToStep: true,
          stepToRedirect: 1,
          waitCnpj: false,
        });
        this.setState({ loading: false });
        return false;
      }

      // Check If Company is registered
      const CodCompany = await this.checkIfCompanyIsRegistered();

      // If is registered, check if there is an open process
      if (CodCompany) {
        // update redux store
        this.props.updateCodEmpresa(CodCompany);
        const hasProcess = await this.checkIfHasOpenProcess(CodCompany);

        // If there is no open process, create one
        if (!hasProcess || hasProcess.length === 0) {
          let valAnalise = stateValorAnalise;
          // Check if there is a closed or canceled process for this company
          const lastClosedProcess = await this.checkIfHasClosedProcess(
            resource.cnpj
          );

          // Se existe um processo fechado com o codStatusCertificacao 3 (reprovado) pra essa empresa, checa a data de finalizado desse processo reprovado, para poder definir o valor analise que será msotrado ao usuario.
          if (
            lastClosedProcess &&
            lastClosedProcess.codStatusCertificacao === '3'
          ) {
            if (
              Number(
                lastClosedProcess.diasProcesso >= 30 &&
                  Number(lastClosedProcess.diasProcesso)
              ) <= 90
            ) {
              valAnalise = stateValorAnalise / 2;
            }

            if (Number(lastClosedProcess.diasProcesso > 90)) {
              valAnalise = stateValorAnalise;
            }
          }

          const registeredCompanyProcess = await this.registerProcess(
            CodCompany,
            valAnalise
          );

          console.log(registeredCompanyProcess);

          // If the process recording was not successfull, throw error and stop wizard.
          if (registeredCompanyProcess.codProcesso === 0) {
            this.clearFirstStep();
            this.setState({
              modalTitle: 'ATENÇÃO',
              modalMessage: 'Erro ao gravar. Tente novamente mais tarde.',
              showModal: true,
              waitCnpj: false,
            });
            this.setState({ loading: false });
            return false;
          }
          // update redux store
          this.props.updateCodProcesso(registeredCompanyProcess.codProcesso);
          this.props.UpdateValorAnalise(valAnalise);
          // If the process recording was successfull, record the fill attempt and go to step 2
          this.recordFillAttempt(CodCompany, 1);
          return true;
        }

        // Se a empresa ja possui processo aberto, verifica em que passo está
        this.props.updateCodProcesso(hasProcess.codProcesso);
        console.log('hasProcess.valorAnalise', hasProcess);
        this.props.UpdateValorAnalise(hasProcess.valorAnalise);

        // If Step 1
        //Exibi mensagem, grava tentativa de preenchimento e manda para o passo 2
        if (hasProcess.codStatusProcesso === '1') {
          this.setState({
            modalTitle: 'ATENÇÃO',
            modalMessage:
              'Sua empresa já esta cadastrada e está aguardando o restante do preenchimento do cadastro. Direcionando para o passo 2.',
            showModal: true,
            redirectToStep: true,
            stepToRedirect: 1,
            waitCnpj: false,
          });
          if (!this.props.alreadySavedFillAttemptStep1) {
            this.recordFillAttempt(CodCompany, 2);
            this.props.updateFillAttempt_Step_1(true);
          }
          this.setState({ loading: false });
          return false;
        }

        // Se está no status 2 (Aguardando pagamento), verifica se o transactionCode está nulo.
        if (hasProcess.codStatusProcesso === '2') {
          /*
           * Se empresa não possui transactionCode, direciona para o passo 3 egrava os códigos nos inputs
           *
           * Antes, grava a tentativa de preenchimento com status 3 (Encaminhado para pagamento)
           */
          if (hasProcess.pagSeguroTransactionCode === null) {
            this.setState({
              modalTitle: 'ATENÇÃO',
              modalMessage:
                'Sua empresa concluiu o cadastro, mas ainda não realizou o pagamento. Direcionando para a seção de pagamento.',
              showModal: true,
              redirectToStep: true,
              stepToRedirect: 2,
              waitCnpj: false,
            });
            if (!this.props.alreadySavedFillAttemptStep2) {
              this.recordFillAttempt(CodCompany, 3);
              this.props.updateFillAttempt_Step_2(true);
            }
            // pula para o passo 3!!!!
            this.setState({ loading: false });
            return false;
          } else {
            /**
             * Se empresa já possui o transactionCode está apenas aguardando o processamento do
             * pagamento e não precisa continuar no processo. Exibe mensagem e envia para a
             * página principal.
             *
             * Antes, grava a tentativa de preenchimento com status 4 (Direcionado para a pagina principal)
             */
            if (!this.props.alreadySavedFillAttemptStep3) {
              this.recordFillAttempt(CodCompany, 4);
              this.props.updateFillAttempt_Step_3(true);
            }
            this.setState({
              modalTitle: 'ATENÇÃO',
              modalMessage:
                'Sua empresa já está cadastrada e aguardando processamento do pagamento. Nao é necessário novo cadastro. Direcionando para página principal.',
              showModal: true,
              waitCnpj: false,
              redirectToHome: true,
            });
            this.setState({ loading: false });
            return false;
            // Redireciona para pagina principal
          }
        }

        if (
          hasProcess.codStatusProcesso === '3' ||
          hasProcess.codStatusProcesso === '4' ||
          hasProcess.codStatusProcesso === '5'
        ) {
          this.setState({
            modalTitle: 'ATENÇÃO',
            modalMessage:
              'Sua empresa já está cadastrada e aguardando análise. Nao é necessário novo cadastro. Direcionando para página principal.',
            showModal: true,
            waitCnpj: false,
            redirectToHome: true,
          });
          this.setState({ loading: false });
          // Redireciona para pagina principal
          return false;
        }
      }

      //If not registered, register the company
      if (!CodCompany) {
        const codRegisteredCompany = await this.registerCompany();

        if (codRegisteredCompany.codEmpresa === null) {
          this.clearFirstStep();
          this.setState({
            modalTitle: 'ATENÇÃO',
            modalMessage: 'Erro ao gravar. Tente novamente mais tarde.',
            showModal: true,
            waitCnpj: false,
          });
          this.setState({ loading: false });
          return false;
        }
        // update redux store
        this.props.updateCodEmpresa(codRegisteredCompany.codEmpresa);
        // Register the process
        const registeredCompanyProcess = await this.registerProcess(
          codRegisteredCompany.codEmpresa,
          stateValorAnalise
        );

        // Se deu erro ao gravar processo, mostra erro e não passa para proximo step
        if (registeredCompanyProcess.codProcesso === 0) {
          this.clearFirstStep();
          this.setState({
            modalTitle: 'ATENÇÃO',
            modalMessage: 'Erro ao gravar. Tente novamente mais tarde.',
            showModal: true,
            waitCnpj: false,
          });
          this.setState({ loading: false });
          return false;
        }
        this.props.updateCodProcesso(registeredCompanyProcess.codProcesso);
        this.props.UpdateValorAnalise(stateValorAnalise);
        // If all goes right, Record fill attempt and go to step 2
        this.recordFillAttempt(codRegisteredCompany.codEmpresa, 1);
        return true;
      }
      return true;
    } else {
      // if anything fails then update the UI validation state but NOT the UI Data State
      this.setState(
        Object.assign(
          userInput,
          validateNewInput,
          this._validationErrors(validateNewInput)
        )
      );
    }

    this.setState({ loading: false });
    return false;
  }

  _validationErrors(val) {
    const errMsgs = {
      cnpjValMsg: val.cnpjVal ? '' : 'CNPJ é Obrigatório',
      nomeEmpresaValMsg: val.nomeEmpresaVal
        ? ''
        : 'Nome da Empresa é Obrigatório',
      telefoneValMsg: val.telefoneVal ? '' : 'Telefone é Obrigatório',
      segmentoValMsg: val.segmentoVal ? '' : 'Segmento é Obrigatório',
      segmento_outrosValMsg: val.segmento_outrosVal
        ? ''
        : 'Por favor, especifique o segmento',
      processoValMsg: val.processoVal ? '' : 'Processo é Obrigatório',
      emailValMsg: val.emailVal ? '' : 'Um E-mail válido é Obrigatório',
    };
    return errMsgs;
  }

  _validateData(data) {
    return {
      cnpjVal: data.cnpj != '', // required: anything besides N/A
      nomeEmpresaVal: data.nomeEmpresa != '', // required: anything besides N/A
      telefoneVal: data.telefone != '', // required: anything besides N/A
      segmentoVal: data.segmento.label != '', // required: anything besides N/A
      segmento_outrosVal:
        data.segmento.value != 2 ||
        (data.segmento.value == 2 && data.segmento_outros != ''), //required: If segmento iquals "Outros"
      processoVal: data.processo != '', // required: anything besides N/A
      emailVal: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        data.email
      ), // required: regex w3c uses in html5
    };
  }

  _grabUserInput() {
    const { resource } = this.state;
    return {
      cnpj: resource.cnpj,
      nomeEmpresa: resource.nomeEmpresa,
      email: resource.email,
      telefone: resource.telefone,
      segmento: resource.segmento,
      segmento_outros: resource.segmento_outros,
      processo: resource.processo,
      promo_code: resource.promo_code,
    };
  }

  // not required as this component has no forms or user entry
  // isValidated() {}

  render() {
    const {
      resource,
      waitCnpj,
      showModal,
      modalMessage,
      lockInput,
      changeCnpj,
      segmentSelectOptions,
      selectedSegment,
      processSelectOptions,
      selectedProcess,
      redirectToHome,
      redirectToStep,
      stepToRedirect,
      modalTitle,
      loading,
      telefoneReceita,
    } = this.state;
    let notValidClasses = {};

    if (
      typeof this.state.segmentoVal == 'undefined' ||
      this.state.segmentoVal
    ) {
      notValidClasses.segmentoCls = 'no-error col-md-8';
    } else {
      notValidClasses.segmentoVal = 'has-error col-md-8';
      notValidClasses.segmentoValGrpCls = 'val-err-tooltip';
    }

    if (
      typeof this.state.segmento_outrosVal == 'undefined' ||
      this.state.segmento_outrosVal
    ) {
      notValidClasses.segmento_outrosCls = 'no-error col-md-8';
    } else {
      notValidClasses.segmento_outrosVal = 'has-error col-md-8';
      notValidClasses.segmento_outrosValGrpCls = 'val-err-tooltip';
    }

    if (typeof this.state.cnpjVal == 'undefined' || this.state.cnpjVal) {
      notValidClasses.cnpjCls = 'no-error col-md-8';
    } else {
      notValidClasses.cnpjVal = 'has-error col-md-8';
      notValidClasses.cnpjValGrpCls = 'val-err-tooltip';
    }

    if (typeof this.state.emailVal == 'undefined' || this.state.emailVal) {
      notValidClasses.emailCls = 'no-error col-md-8';
    } else {
      notValidClasses.emailCls = 'has-error col-md-8';
      notValidClasses.emailValGrpCls = 'val-err-tooltip';
    }

    if (
      typeof this.state.nomeEmpresaVal == 'undefined' ||
      this.state.nomeEmpresaVal
    ) {
      notValidClasses.nomeEmpresaCls = 'no-error col-md-8';
    } else {
      notValidClasses.nomeEmpresaCls = 'has-error col-md-8';
      notValidClasses.nomeEmpresaValGrpCls = 'val-err-tooltip';
    }

    if (
      typeof this.state.telefoneVal == 'undefined' ||
      this.state.telefoneVal
    ) {
      notValidClasses.telefoneCls = 'no-error col-md-8';
    } else {
      notValidClasses.telefoneCls = 'has-error col-md-8';
      notValidClasses.telefoneValGrpCls = 'val-err-tooltip';
    }

    if (
      typeof this.state.processoVal == 'undefined' ||
      this.state.processoVal
    ) {
      notValidClasses.processoCls = 'no-error col-md-8';
    } else {
      notValidClasses.processoCls = 'has-error col-md-8';
      notValidClasses.processoValGrpCls = 'val-err-tooltip';
    }

    return (
      <div className="step step1">
        <div className="row">
          <form className="needs-validation" noValidate>
            <p>
              Preencha os dados solicitados para iniciar o processo de
              certificação
            </p>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <Label htmlFor="validationCustom01">
                  CNPJ*
                  {changeCnpj && (
                    <Smallbtn onClick={this.ChangeCnpjInput}>
                      (Editar CNPJ)
                    </Smallbtn>
                  )}
                </Label>
                <InputMask
                  className="form-control"
                  mask="99.999.999/9999-99"
                  maskChar={null}
                  alwaysShowMask={false}
                  value={resource.cnpj}
                  onChange={event =>
                    this.handleChange('cnpj', event.target.value)
                  }
                  onBlur={this.cnpjReceita}
                  disabled={lockInput ? 'disabled' : ''}
                />

                <div className={notValidClasses.cnpjValGrpCls}>
                  {this.state.cnpjValMsg}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <Label htmlFor="validationCustom02">Nome da Empresa*</Label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom02"
                  placeholder={waitCnpj ? 'Aguarde...' : ''}
                  value={resource.nomeEmpresa}
                  onChange={event =>
                    this.handleChange('nomeEmpresa', event.target.value)
                  }
                  onBlur={this.validationCheck}
                  disabled={lockInput ? 'disabled' : ''}
                  required
                />
                <div className={notValidClasses.nomeEmpresaValGrpCls}>
                  {this.state.nomeEmpresaValMsg}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-6 mb-3">
                <Label htmlFor="validationCustom03">E-mail*</Label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom03"
                  value={resource.email}
                  onChange={event =>
                    this.handleChange('email', event.target.value)
                  }
                  onBlur={this.validationCheck}
                  required
                />
                <div className={notValidClasses.emailValGrpCls}>
                  {this.state.emailValMsg}
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <Label htmlFor="validationCustom04">Telefone*</Label>
                {telefoneReceita && (
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom04"
                    value={resource.telefone}
                    placeholder={waitCnpj ? 'Aguarde...' : ''}
                    onChange={event =>
                      this.handleChange('telefone', event.target.value)
                    }
                    onBlur={this.validationCheck}
                    required
                  />
                )}
                {!telefoneReceita && (
                  <InputMask
                    className="form-control"
                    mask="(99) 99999-9999"
                    maskChar={null}
                    alwaysShowMask={false}
                    value={resource.telefone}
                    placeholder={waitCnpj ? 'Aguarde...' : ''}
                    onChange={event =>
                      this.handleChange('telefone', event.target.value)
                    }
                    onBlur={this.validationCheck}
                    beforeMaskedValueChange={this.beforeMaskedValueChange}
                  />
                )}
                <div className={notValidClasses.telefoneValGrpCls}>
                  {this.state.telefoneValMsg}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-6 mb-3">
                <Label htmlFor="validationCustom03">Segmento*</Label>
                <Select
                  name="status"
                  value={selectedSegment}
                  onChange={this.handleChangeOnSegment}
                  options={segmentSelectOptions}
                  placeholder="Select"
                  isClearable={false}
                  onBlur={this.validationCheck}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
                <div className={notValidClasses.segmentoValGrpCls}>
                  {this.state.segmentoValMsg}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <Label htmlFor="validationCustom04">
                  Caso Outros, determine
                </Label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom04"
                  disabled={resource.segmento.value !== 2 ? 'disabled' : ''}
                  onBlur={this.validationCheck}
                  value={resource.segmento_outros}
                  onChange={event =>
                    this.handleChange('segmento_outros', event.target.value)
                  }
                  required
                />
                <div className={notValidClasses.segmento_outrosValGrpCls}>
                  {this.state.segmento_outrosValMsg}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-6 mb-3">
                <Label htmlFor="validationCustom03">Processo</Label>
                <Select
                  name="status"
                  value={selectedProcess}
                  onChange={this.handleChangeOnProcess}
                  options={processSelectOptions}
                  placeholder="Select"
                  isClearable={false}
                  onBlur={this.validationCheck}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
                <div className={notValidClasses.processoValGrpCls}>
                  {this.state.processoValMsg}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <Label htmlFor="validationCustom04">Código Promocional</Label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom04"
                  value={resource.promo_code}
                  onChange={event =>
                    this.handleChange('promo_code', event.target.value)
                  }
                  required
                />
                <div className="invalid-feedback">
                  Please provide a valid state.
                </div>
              </div>
            </div>
          </form>
        </div>
        <Modal
          {...this.props}
          show={showModal}
          onHide={() => this.setState({ showModal: false })}
          title={modalTitle}
          body={modalMessage}
          showSaveButton={false}
          redirectToHome={redirectToHome}
          redirectToStep={redirectToStep}
          stepToRedirect={stepToRedirect}
        />

        {loading && (
          <div className="text-center">
            <ClipLoader
              sizeUnit={'px'}
              size={30}
              color={'#123abc'}
              loading={loading}
            />
            <p>Carregando...</p>
          </div>
        )}
      </div>
    );
  }
}

export default Step1;
