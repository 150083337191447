import { UPDATE_RESOURCE_1, UPDATE_RESOURCE_2, UPDATE_ACTIVE_STEP, UPDATE_FILL_ATTEMPT_STEP_1, UPDATE_COD_EMPRESA, UPDATE_COD_PROCESSO,UPDATE_FILL_ATTEMPT_STEP_2, UPDATE_FILL_ATTEMPT_STEP_3, UPDATE_ENDERECO_DIVULGA, UPDATE_VALOR_ANALISE } from './actionTypes';

export const updateActiveStep = value => ({
  type: UPDATE_ACTIVE_STEP,
  newValue: value
});

export const updateFillAttempt_Step_1 = value => ({
  type: UPDATE_FILL_ATTEMPT_STEP_1,
  newValue: value
});

export const updateFillAttempt_Step_2 = value => ({
  type: UPDATE_FILL_ATTEMPT_STEP_2,
  newValue: value
});

export const updateFillAttempt_Step_3 = value => ({
  type: UPDATE_FILL_ATTEMPT_STEP_3,
  newValue: value
});

export const updateResourceStep1 = value => ({
  type: UPDATE_RESOURCE_1,
  newValue: value
});

export const updateRes2 = value => ({
  type: UPDATE_RESOURCE_2,
  newValue: value
});

export const updateEnderecoDivulga = value => ({
  type: UPDATE_ENDERECO_DIVULGA,
  newValue: value
});

export const updateCodEmpresa = value => ({
  type: UPDATE_COD_EMPRESA,
  newValue: value
});

export const updateCodProcesso = value => ({
  type: UPDATE_COD_PROCESSO,
  newValue: value
});

export const UpdateValorAnalise = value => ({
  type: UPDATE_VALOR_ANALISE,
  newValue: value
});